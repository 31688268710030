<template>
  <div>
    <portal to="body-top">
      <customer-name title="- Add an Address" />
    </portal>
    <address-form
      ref="address-form"
      :is-create-form="true"
    />
  </div>
</template>
<script>
import AddressForm from '../components/AddressForm.vue'
import CustomerName from '../../../components/CustomerName.vue'

export default {
  name: 'CustomerCreate',
  components: {
    AddressForm,
    CustomerName,
  },
  data() {
    return {
      defaultCountryId: null,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['address-form']) {
      this.$refs['address-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  async created() {
    await this.$store.dispatch('listModule/organizationData')
      .then(res => {
        const { data } = res.data
        this.defaultCountryId = data.detail?.country
      })

    await this.$store.dispatch(`${this.MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME}/getDefaultSettings`)
      .then(res => {
        const { data } = res.data

        const defaultData = {
          country_id: this.defaultCountryId,
          is_active: data.address_status,
        }

        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, defaultData)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_ADDRESS_FORM_CLONE`, defaultData)
      })
  },
  setup() {
    const MODULE_NAME = 'address'
    const MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME = 'settings-customer'
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME,
      MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
